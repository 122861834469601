<template>
    <Modal label="Data Slider">

        <UploadFile
            label="Image Slider"
            :fileData="fileData"
            :error="error.image"
            :errorMsg="errorMsg.image"
            @setFileData="onSetFileData"
            @resetError="resetError('image')"
            @setError="setError($event)"
            :accept="accept"
        >

        </UploadFile>

        <InputCustom
            v-model="item.urutan" 
            :error="error.urutan"
            :errorMsg="errorMsg.urutan"
            @onFocus="resetError('urutan')"
            placeholder="Urutan"
            label="Urutan Slider"
        >
        </InputCustom>
        
        <template #actionButton>
            <ButtonCustom
                :disabled="loading"
                type="secondary"
                size="sm"
                class="r-mlr-10"
                @click.prevent="resetData()"
            >
                Batal
            </ButtonCustom>
            
            <ButtonCustom
                :disabled="loading"
                type="primary"
                size="sm"
                class="r-mlr-10"
                @click.prevent="sendData()"
            >
                {{inputType}}
            </ButtonCustom>
        </template>
    </Modal>
</template>
<script>
import common from '@/mixins/function/common'
import { mapGetters, mapActions } from 'vuex'
import Modal from '@/components/Helper/Modal'
import validation from '@/mixins/function/validation'
import UploadFile from '@/components/Helper/UploadFile'

export default {
    mixins: [
        common,
        validation
    ],
    components: {
        Modal,
        UploadFile
    },
    mounted() {
        this.loadSliders = true
    },
    props: {
        inputType: {
            require: true,
        }
    },
    computed: {
        ...mapGetters({
            item: 'slider/getItem',
            fileData: 'slider/getFileData',
        })
    },
    data: () => ({
        loadSliders: false,
        accept: 'image/*',
        error: {
            urutan: null,
            image: null
        },
        errorMsg: {
            urutan: 'Tidak boleh kosong',
            image: 'Tidak boleh kosong'
        },
        loading: false,
    }),
    methods: {
        ...mapActions({
            store: 'slider/store',
            update: 'slider/update',
            setFileData: 'slider/setFileData',
        }),
        resetData() {
            this.$emit('resetData')
        },
        onSetFileData(data){
            this.setFileData(data)
        },
        validation() {
            var error = 0
            if(this.item.urutan !== null && this.item.urutan !== ""){
                if(!(/^\d+$/.test(this.item.urutan))){
                    this.error.urutan = true
                    this.errorMsg.urutan = "Hanya boleh angka"
                    error += 1;
                } else{
                    this.error.urutan = false
                }
            }
            return error
        },
        prepareData(data){
            const formData = new FormData()
            formData.append('urutan', data.urutan)
            if(this.fileData.file) formData.append('image',this.fileData.file)
            return formData
        },
        async sendData() {
            if (this.validation() === 0) {
                const payload = this.prepareData(this.item)
                this.loading = true
                if (this.inputType == 'Tambah') {
                    await this.store(payload)
                } else {
                    await this.update({payload, id: this.item.id})
                }
                this.loading = false
                const response = this.getResponse('slider')
                this.showSnackbar({
                    type: response.status == 1 ? 'success' : 'error',
                    text: response.msg
                })

                if (response.status === 1) {
                    this.resetData()
                    this.$emit('getData')
                }
            } else {
                this.showSnackbar ({
                    type: 'error',
                    text: 'Mohon periksa kembali'
                })
            }
        }
    }
}
</script>